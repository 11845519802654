const filters = document.querySelector("[data-filters]");

const getFilterData = filterElements => {
	let obj = {};

	filterElements.forEach(target => {
		const { type, name, value } = target;

		if (type === "radio" || type === "checkbox") {
			// If it’s a checkbox or radio button, handle checked items
			if (target.checked) {
				// Initialize an array if not already set
				if (!obj[name]) {
					obj[name] = [];
				}
				// Add the checked value to the array
				obj[name].push(value);
			}
		} else {
			// For non-checkbox/radio inputs, just assign the value
			obj[name] = value;
		}
	});

	return obj;
};

const obj2QueryString = obj => {
	return Object.keys(obj)
		.map(key => {
			let val = obj[key];

			// If there are multiple values (array), encode each one separately
			if (Array.isArray(val)) {
				return val
					.map(v => {
						return encodeURIComponent(key) + "=" + encodeURIComponent(v);
					})
					.join("&");
			} else {
				// If it's a single value, just encode the key-value pair
				return encodeURIComponent(key) + "=" + encodeURIComponent(val);
			}
		})
		.join("&");
};

const queryString2Obj = str => {
	if (!str.length) return {};
	const arr = str.split("&");
	const obj = arr.reduce((o, v) => {
		const [key, val] = v.split("=");
		if (!o[key]) {
			o[key] = [];
		}
		o[key].push(decodeURIComponent(val));
		return o;
	}, {});
	return obj;
};

const getContent = filterElements => {
	const data = getFilterData(filterElements);
	const newQ = obj2QueryString(data);

	const oldListingContainer = document.querySelector("[data-filtered-content]");
	const loader = document.createElement("div");
	loader.setAttribute("class", "loader");
	if (oldListingContainer) {
		oldListingContainer.prepend(loader);
	}

	const newLocation = location.origin + location.pathname + "?" + newQ;

	fetch(newLocation)
		.then(response => {
			history.pushState("", "", newLocation);
			return response.text();
		})
		.then(data => {
			const parser = new DOMParser();
			const htmlResponse = parser.parseFromString(data, "text/html");
			const newListingContainer = htmlResponse.documentElement.querySelector(
				"[data-filtered-content]"
			);
			if (newListingContainer) {
				oldListingContainer.innerHTML = newListingContainer.innerHTML;
			}
			init_calendars();
		})
		.catch(error => {
			console.log("Error:", error);
		});
};

const initListeners = filterElements => {
	Array.prototype.forEach.call(filterElements, element => {
		element.addEventListener(
			"change",
			event => {
				getContent(filterElements);
			},
			false
		);
	});

	document.body.addEventListener("click", function(event) {
		if (event.target.dataset.filtersUpdate != undefined) {
			getContent(filterElements);
		}
	});

	// Add event listener for button type='submit'
	const submitButtons = document.querySelectorAll("button[type='submit']");
	submitButtons.forEach(button => {
		button.addEventListener(
			"click",
			event => {
				event.preventDefault();
				getContent(filterElements);
			},
			false
		);
	});

	// Add event listener for button type='button'
	const buttonButtons = document.querySelectorAll("button[type='button'].option");
	buttonButtons.forEach(button => {
		button.addEventListener(
			"click",
			event => {
				setTimeout(() => {
					event.preventDefault();
					getContent(filterElements);
				}, 200);
			},
			false
		);
	});
};

const initFilters = filters => {
	if (!filters) {
		return;
	}

	const filterElements = filters.querySelectorAll(`select, input`);

	if (!filterElements.length) {
		return;
	}

	getFilterData(filterElements);
	initListeners(filterElements);
};

initFilters(filters);
